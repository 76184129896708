import React, { useRef, useState } from 'react';
import { Button, FormControlLabel, Grid, Link, Switch, TextField, Typography } from '@material-ui/core';
import { useDidMount } from 'react-hooks-lib';
import WarningIcon from '@material-ui/icons/Warning';
import { WARN_COLOR } from '../style/colors';

function OilChangersSitePicker({ remainingCount, onSubmit }) {
  const [size, setSize] = useState(21780);
  const [lat, setLat] = useState(41.0065385);
  const [long, setLong] = useState(-73.6470626);
  const [numberOfBays, setNumberOfBays] = useState(2);
  const [accessPoints, setAccessPoints] = useState(2);
  const [existingSite, setExistingSite] = useState(false);
  const [wasSubmit, setWasSubmit] = useState(false);
  const mapRef = useRef(null);
  const inputRef = useRef(null);
  const latRef = useRef(null);
  const longRef = useRef(null);
  useDidMount(() => {
    const el = window.$(mapRef.current);
    const input = window.$(inputRef.current);
    const latInput = window.$(latRef.current);
    const longInput = window.$(longRef.current);
    el.locationpicker({
      location: { latitude: lat, longitude: long },
      radius: 0,
      enableReverseGeocode: false,
      inputBinding: {
        latitudeInput: latInput,
        longitudeInput: longInput,
        locationNameInput: input,
      },
      enableAutocomplete: true,
      onchanged: function (currentLocation, radius, isMarkerDropped) {
        setLat(currentLocation.latitude);
        setLong(currentLocation.longitude);
      },
    });
  });

  const latError = lat === 0;
  const longError = long === 0;
  const sizeError = size <= 0;
  const numberOfBaysError = numberOfBays <= 0;
  const accessPointsError = accessPoints <= 0;

  function hasErrors() {
    return latError || longError || sizeError || numberOfBaysError || accessPointsError;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setWasSubmit(true);
    if (!hasErrors()) {
      onSubmit(lat, long, size, numberOfBays, accessPoints, existingSite);
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid component="form" onSubmit={handleSubmit} container spacing={2} direction="column" item md={6} xs={12}>
        <Grid item>
          <TextField
            fullWidth
            inputProps={{
              style: { backgroundColor: 'white', height: 28, paddingLeft: 8 },
            }}
            inputRef={inputRef}
            label="Address"
            placeholder="Start typing site address"
            InputLabelProps={{
              shrink: true,
            }}
            margin="none"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            helperText={wasSubmit && latError && 'Latitude should not be 0'}
            error={wasSubmit && latError}
            fullWidth
            inputRef={latRef}
            label="Latitude"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 'any',
              style: { backgroundColor: 'white', height: 28, paddingLeft: 8 },
            }}
            margin="none"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            helperText={wasSubmit && longError && 'Longitude should not be 0'}
            error={wasSubmit && longError}
            fullWidth
            inputRef={longRef}
            label="Longitude"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 'any',
              style: { backgroundColor: 'white', height: 28, paddingLeft: 8 },
            }}
            margin="none"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            helperText={wasSubmit && sizeError && 'Size should be greater than 0'}
            error={wasSubmit && sizeError}
            value={size}
            type="number"
            onChange={(e) => setSize(e.target.value)}
            fullWidth
            label="Lot size (ft²)"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
              style: { backgroundColor: 'white', height: 28, paddingLeft: 8 },
            }}
            margin="none"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            helperText={wasSubmit && numberOfBaysError && 'Number of bays should be greater than 0'}
            error={wasSubmit && numberOfBaysError}
            value={numberOfBays}
            type="number"
            onChange={(e) => setNumberOfBays(e.target.value)}
            fullWidth
            label="Number of bays"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
              style: { backgroundColor: 'white', height: 28, paddingLeft: 8 },
            }}
            margin="none"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            helperText={wasSubmit && accessPointsError && 'Access points should be greater than 0'}
            error={wasSubmit && accessPointsError}
            value={accessPoints}
            type="number"
            onChange={(e) => setAccessPoints(e.target.value)}
            fullWidth
            label="Access points"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
              style: { backgroundColor: 'white', height: 28, paddingLeft: 8 },
            }}
            margin="none"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={existingSite}
                onChange={(event) => {
                  const checked = event.target.checked;
                  setExistingSite(checked);
                }}
                name="existingSite"
              />
            }
            label="This is existing site"
          />
        </Grid>
        <Grid style={{ marginTop: 16 }} item>
          <Button type="submit" fullWidth color="secondary" variant="contained">
            Analyze location
          </Button>
        </Grid>
        {remainingCount !== undefined && (
          <Grid style={{ marginTop: 4 }} item>
            <Grid container align="center">
              {remainingCount === 0 && (
                <WarningIcon style={{ color: WARN_COLOR, marginRight: 8, width: 16, height: 16 }} />
              )}
              <Typography variant="body2" color="textSecondary">
                <strong>{remainingCount}</strong>
                {remainingCount === 1 ? ' query' : ' queries'} remaining,{' '}
                <Link href="mailto:support@omnixlabs.com">contact support</Link> to expand
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <div ref={mapRef} style={{ width: '100%', height: 500 }} />
      </Grid>
    </Grid>
  );
}

export default OilChangersSitePicker;
